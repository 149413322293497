function requireAll(r) { r.keys().forEach(r); }

require("@rails/ujs").start()
require("turbolinks").start()

require('vendor/bootstrap')

import Dygraph from 'dygraphs';
window.Dygraph = Dygraph;
require('dygraphs/src/extras/synchronizer')

requireAll(require.context('components/', true, /\.js$/))
requireAll(require.context('views/', true, /\.js$/))

require.context('images/', true, /\.(gif|jpg|png|svg)$/)
require('./stylesheets');

Turbolinks.setProgressBarDelay(0);
