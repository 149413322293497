document.addEventListener('turbolinks:load', function() {
  document.querySelectorAll('[data-confirm-prompt]').forEach(elem => {
    elem.addEventListener('click', e => {
      let input = prompt(elem.dataset.confirmPrompt);
      if (input !== elem.dataset.confirmPromptKey) {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  });
});
