import TimeRange from 'components/time_range';
import doubleClickZoomOutPlugin from 'vendor/dygraph/double_click_zoom_out_plugin';

const alertBackgroundColors = {
  orange: 'rgba(255, 179, 0, 0.5)',
  red: 'rgba(220, 53, 69, 0.4)'
}

function drawAlert(canvas, area, g, min, max, alert, color = 'red') {
  let bottom_left = g.toDomCoords(new Date(alert[0]), min);
  let top_right = g.toDomCoords(new Date(alert[1]), max);
  let left = bottom_left[0];
  let right = top_right[0];
  canvas.fillStyle = alertBackgroundColors[color];
  canvas.fillRect(left, area.y, right - left, area.h);
}

document.addEventListener('turbolinks:load', function() {
  let component = document.querySelector('.v-uptime-checks-charts');
  if (component) {
    new ServerCharts(component);
  }
});

class ServerCharts {
  constructor(component) {
    this.component       = component;
    this.chartsContainer = component.querySelector('.v-uptime-checks-charts-container .row');
    this.aggregationText = component.querySelector('.v-uptime-checks-charts-aggregation-text');
    this.infoBox         = component.querySelector('.v-uptime-checks-charts-infos');
    this.initTimeRange();
  }

  instances = [];
  defaultChartOptions = {
    animatedZooms: true,
    colors: ['#0288D1', '#68BD5C'],
    legend: 'hover',
    plugins: [
      doubleClickZoomOutPlugin
    ],
    strokeWidth: 1.5,
    customBars: true,
  };

  buildCharts(response) {
    this.aggregationText.innerHTML = response.time_bucket;
    let charts = this.graphSettings(response);
    this.infoBox.classList.remove('invisible');
    Object.keys(charts).forEach(key => {
      let chart = charts[key];
      let elem = this.createHtml(chart);
      this.instances.push(new Dygraph(
        elem,
        chart.data,
        Object.assign({ }, this.defaultChartOptions, chart.options)
      ));
    });
    if (this.instances.length > 1) {
      Dygraph.synchronize(...this.instances, { range: false });
    }
  }

  createHtml(chart) {
    let container = document.createElement('div');
    container.classList.add('chart', 'col-12', 'py-5', 'px-3', 'position-relative');
    if (!chart.data.length) { container.classList.add('chart-empty') }
    let title = document.createElement('h2');
    title.classList.add('h5', 'text-center', 'text-uppercase');
    title.innerHTML = chart.title;
    let bloc = document.createElement('div');
    container.appendChild(title);
    container.appendChild(bloc);
    this.chartsContainer.appendChild(container);
    return bloc;
  }

  destroyInstances() {
    if (this.instances) {
      this.instances.forEach(instance => { instance.destroy() });
    }
    this.chartsContainer.querySelectorAll('.chart').forEach(c => c.remove());
    this.instances = [];
  }

  graphSettings(response) {
    ['response_time_metrics'].forEach(key => {
      response[key].forEach(array => {
        array[0] = new Date(array[0]);
      });
    });

    let charts = {
      response_time: {
        title: 'Response time',
        data: response.response_time_metrics,
        options: {
          labels: ['Date', 'Response time'],
          axes: {
            y: {
              valueFormatter: (y, opts, series_name) => { return Math.round(y*1000)/1000 + 's' },
              axisLabelFormatter: (n, granularity, opts, dygraph) => { return Math.round(n*1000)/1000 + 's' }
            }
          },
          underlayCallback: (canvas, area, g) => {
            response.uptime_alerts.forEach(alert => drawAlert(canvas, area, g, 0, 100, alert));
            response.response_time_alerts.forEach(alert => drawAlert(canvas, area, g, 0, 100, alert, 'orange'));
          }
        }
      },
    }

    // Force to display full time period on x-axis (if needed)
    if (response.response_time_metrics.length) {
      let allowedGap = 60 * 1000;
      let periodStartGap = new Date(response.response_time_metrics[0][0]) - this.rangeFrom;
      let periodEndGap = this.rangeTo - new Date(response.response_time_metrics[response.response_time_metrics.length-1][0]);

      if (periodStartGap > allowedGap) {
        Object.entries(charts).forEach(entry => {
          entry[1].data.unshift(entry[0] == 'network' ? [this.rangeFrom, null, null] : [this.rangeFrom, null]);
        });
      }
      if (periodEndGap > allowedGap) {
        Object.entries(charts).forEach(entry => {
          entry[1].data.push(entry[0] == 'network' ? [this.rangeTo, null, null] : [this.rangeTo, null]);
        });
      }
    }

    return charts;
  }

  initTimeRange() {
    let lastPeriod = null;
    new TimeRange(this.component.querySelector('.time-range'), (period, from) => {
      if (period !== lastPeriod) {
        this.infoBox.classList.add('invisible');
      }
      lastPeriod = period;
      this.loadData(period, from);
    });
  }

  loadData(period, from) {
    if (this.abortController) { this.abortController.abort() }
    this.abortController = new AbortController();
    const scrollY = window.scrollY;
    this.component.style.height = this.component.clientHeight + 'px';
    this.destroyInstances();
    Turbolinks.controller.adapter.progressBar.setValue(0);
    Turbolinks.controller.adapter.progressBar.show();
    fetch(`/uptime_checks/${window.location.pathname.split('/')[2]}/charts_data?period=${period}&from=${from}`, { signal: this.abortController.signal })
      .then(response => {
        return response.json();
      })
      .then(response => {
        this.rangeFrom = new Date(response.from);
        this.rangeTo = new Date(response.to);
        this.buildCharts(response);
        this.component.style.height = 'auto';
        window.scrollTo(0, scrollY);
        Turbolinks.controller.adapter.progressBar.hide();
        Turbolinks.controller.adapter.progressBar.setValue(100);
      })
      .catch(error => {
        if(error.name !== "AbortError") {
          alert("Error: " + error);
        };
      });
  }

  updateUrl() {
    var queryParams = new URLSearchParams(window.location.search);
    history.pushState(null, null, "?"+queryParams.toString());
  }
}

var suffixes = ['', 'k', 'M', 'G', 'T'];
function formatKMB(v) {
  if (v < 1000) return v + 'B/s';

  var magnitude = Math.floor(String(Math.floor(v)).length / 3);
  if (magnitude > suffixes.length - 1)
    magnitude = suffixes.length - 1;
  return String(round(v / Math.pow(10, magnitude * 3), 2)) +
    suffixes[magnitude] + 'B/s';
}

function round(num, places) {
  var shift = Math.pow(10, places);
  return Math.round(num * shift)/shift;
};
