var GColor = function(r,g,b) {
  r = (typeof r === 'undefined')?0:r;
  g = (typeof g === 'undefined')?0:g;
  b = (typeof b === 'undefined')?0:b;
  return {r:r, g:g, b:b};
};

var createColorRange = function(c1, c2) {
  var colorList = [], tmpColor;
  for (var i=0; i<101; i++) {
      tmpColor = new GColor();
      tmpColor.r = c1.r + ((i*(c2.r-c1.r))/101);
      tmpColor.g = c1.g + ((i*(c2.g-c1.g))/101);
      tmpColor.b = c1.b + ((i*(c2.b-c1.b))/101);
      colorList.push(`rgb(${tmpColor.r},${tmpColor.g},${tmpColor.b})`);
  }
  return colorList;
};

var colorRange;

document.addEventListener('turbolinks:load', function() {
  document.querySelectorAll('.gauge:not([data-initialized])').forEach(bloc => {
    bloc.dataset.initialized = true;
    if (!colorRange) { colorRange = createColorRange(new GColor(0, 149, 120), new GColor(250, 188, 0)); }
    const value = bloc.dataset.value;
    const fillValue = Math.min(Math.max(value, 0), 1);
    const divBody = document.createElement("div");
    const divFill = document.createElement("div");
    const divCover = document.createElement("div");
    divBody.className = "gauge-body";
    divFill.className = "gauge-fill";
    divFill.style.transform = `rotate(${fillValue / 2}turn)`;
    divCover.className = "gauge-cover";
    divCover.textContent = `${Math.round(value * 100)}%`;
    divBody.appendChild(divFill);
    divBody.appendChild(divCover);
    bloc.style.setProperty('--gauge-color', colorRange[parseInt(fillValue * 100)]);
    bloc.appendChild(divBody);
  });
});
