import flatpickr from 'flatpickr';
import weekSelect from 'flatpickr/dist/plugins/weekSelect/weekSelect';
import monthSelect from 'flatpickr/dist/plugins/monthSelect/';

const dayjs = require('dayjs');
const isoWeek = require('dayjs/plugin/isoWeek');
dayjs.extend(isoWeek);

class TimeRange {
  periods = ['day', 'week', 'month', 'year'];

  constructor(component, callback) {
    this.component     = component;
    this.callback     = callback;
    this.datePicker = component.querySelector('.time-range-date');
    this.weekPicker = component.querySelector('.time-range-week');
    this.monthPicker = component.querySelector('.time-range-month');
    this.yearPicker = component.querySelector('.time-range-year');
    this.nextButton = component.querySelector('.time-range-next')
    this.prevButton = component.querySelector('.time-range-prev')
    this.periodButtons = component.querySelectorAll('.time-range-nav button')

    let url =  new URL(window.location);
    this.setParams(url.searchParams.get('period'), url.searchParams.get('from'), true);
    this.initPickers();
    this.initListeners();
    this.sticky();
  }

  emitUpdate() {
    if (this.callback) {
      this.callback(this.period, this.from.format());
    }
  }

  initListeners() {
    this.periodButtons.forEach(button => {
      button.addEventListener('click', e => {
        this.setParams(button.dataset.period);
      });
    });
    this.nextButton.addEventListener('click', e => this.navigate('next'));
    this.prevButton.addEventListener('click', e => this.navigate('prev'));
  }

  initPickers() {
    flatpickr(this.datePicker, {
      disableMobile: true,
      mode: 'single',
      defaultDate: this.from.toDate(),
      locale: { firstDayOfWeek: 1 },
      onChange: dates => this.setParams('day', dayjs(dates[0]))
    });
    flatpickr(this.weekPicker, {
      disableMobile: true,
      defaultDate: this.from.toDate(),
      locale: { firstDayOfWeek: 1 },
      onChange: dates => this.setParams('week', dayjs(dates[0]).startOf('isoWeek')),
      plugins: [ new weekSelect({}) ],
    });
    flatpickr(this.monthPicker, {
      disableMobile: true,
      defaultDate: this.from.toDate(),
      onChange: dates => this.setParams('month', dayjs(dates[0]).startOf('month')),
      plugins: [
        new monthSelect({
          shorthand: true,
          theme: 'material_orange'
        })
      ],
    });
  }

  navigate(direction) {
    if (direction === 'prev') {
      this.setParams(this.period, this.from.subtract(1, this.period));
    } else {
      this.setParams(this.period, this.from.add(1, this.period));
    }
  }

  setParams(period=null, from=null, init=false) {
    from = dayjs.isDayjs(from) ? from : dayjs(from);
    this.period = this.periods.includes(period) ? period : 'day';
    this.from = from.isValid() ? from : dayjs().startOf(this.period === 'week' ? 'isoWeek' : this.period);
    this.updateUI();
    this.emitUpdate();
    if (!init) { this.updateUrl() }
  }

  sticky() {
    const stickyToggleFn = e => this.component.classList.toggle('stucked', window.scrollY > 150)
    window.addEventListener('scroll', stickyToggleFn);
    document.addEventListener('turbolinks:load', () => {
      window.removeEventListener('scroll', stickyToggleFn);
    }, { once: true });
  }

  updateActivePeriodButton() {
    this.periodButtons.forEach(button => {
      button.classList.toggle('text-decoration-none', this.period !== button.dataset.period);
    });
  }

  updateActivePicker() {
    [this.datePicker, this.weekPicker, this.monthPicker, this.yearPicker].forEach(picker => picker.classList.add('d-none'));
    if (this.period === 'day') {
      this.datePicker.innerHTML = this.from.format('dddd D MMMM YYYY');
      this.datePicker.classList.remove('d-none');
    } else if (this.period === 'week') {
      this.weekPicker.innerHTML = this.from.format('D MMMM YYYY') + ' - ' + this.from.endOf('isoWeek').format('D MMMM YYYY');
      this.weekPicker.classList.remove('d-none');
    } else if (this.period === 'month') {
      this.monthPicker.innerHTML = this.from.format('MMMM YYYY');
      this.monthPicker.classList.remove('d-none');
    } else if (this.period === 'year') {
      this.yearPicker.innerHTML = this.from.format('YYYY');
      this.yearPicker.classList.remove('d-none');
    }
  }

  updateUI() {
    this.updateActivePeriodButton();
    this.updateActivePicker();
  }

  updateUrl() {
    var queryParams = new URLSearchParams(window.location.search);
    queryParams.set('period', this.period);
    queryParams.set('from', this.from.format());
    let url = `?${queryParams.toString()}`;
    history.replaceState({turbolinks: true, url: url}, null, url);
  }
}

export default TimeRange;
