// https://github.com/twbs/bootstrap/tree/v5.0.0-beta1/js/src

import { Collapse } from 'bootstrap/js/src/collapse';
import { Dropdown } from 'bootstrap/js/src/dropdown';

const Toast = require("bootstrap/js/src/toast").default;
const Tooltip = require("bootstrap/js/src/tooltip").default;

document.addEventListener('turbolinks:load', function() {
  var toastElList = document.querySelectorAll('.toast');
  toastElList.forEach(function (elem) {
    let toast = new Toast(elem);
    toast.show();
    elem.addEventListener('click', e => toast.hide());
  });

  var tooltipElList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  tooltipElList.forEach(function (elem) {
    new Tooltip(elem)
  });
});
