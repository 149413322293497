function toggleElement(checkbox, target) {
  checkbox.setAttribute('aria-expanded', checkbox.checked);
  target.classList.toggle('d-none', !checkbox.checked);
}

document.addEventListener('turbolinks:load', function() {
  document.querySelectorAll('input[data-display-toggle]').forEach(checkbox => {
    let targets = document.querySelectorAll('[aria-labelledby="' + checkbox.id + '"]');
    targets.forEach(target => {
      toggleElement(checkbox, target);
      checkbox.addEventListener('change', e => toggleElement(checkbox, target));
    });
  });
});
