import ClipboardJS from 'clipboard';
const Tooltip = require("bootstrap/js/src/tooltip").default;

document.addEventListener('turbolinks:load', function() {
  var elements = document.querySelectorAll('[data-clipboard-target]');
  elements.forEach(function (elem) {
    let clipboard = new ClipboardJS(elem);
    clipboard.on('success', function(e) {
      let tooltip = new Tooltip(elem, { title: 'copied', trigger: 'manual' });
      tooltip.show();
      setTimeout(() => {
        tooltip.dispose();
      }, 2000);
      e.clearSelection();
    });
  });
});
