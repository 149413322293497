const doubleClickZoomOutPlugin = {
  activate: function(g) {
    const initialValueRange = g.getOption('valueRange');
    return {
      dblclick: e => {
        e.dygraph.updateOptions({
          dateWindow: null,
          valueRange: initialValueRange
        });
        e.preventDefault();
      }
    }
  }
}

export default doubleClickZoomOutPlugin;