function defaultPortByProtocol(protocolId) {
  switch(protocolId) {
    case 1:
      return 80;
    case 2:
      return 443;
    default:
      return '';
  }
}

function updateUI(protocolRadioButtons, rows, portInput) {
  let activeProtocol = protocolRadioButtons.find(p => p.checked)
  let activeProtocolId = activeProtocol ? parseInt(activeProtocol.value) : null;
  portInput.value = defaultPortByProtocol(activeProtocolId);
  rows.forEach(row => {
    row.classList.toggle('d-none', !activeProtocolId || !JSON.parse(row.dataset.protocols).includes(activeProtocolId));
  });
}

document.addEventListener('turbolinks:load', function() {
  const form = document.querySelector('.v-uptime-checks-form');
  if (!form) { return }
  const protocolRadioButtons = Array.from(form.querySelectorAll('input[name="uptime_check[protocol_id]"]'));
  const rows = form.querySelectorAll('[data-protocols]');
  const portInput = document.getElementById('uptime_check_port');
  updateUI(protocolRadioButtons, rows, portInput);
  protocolRadioButtons.forEach(radio => {
    radio.addEventListener('change', e => updateUI(protocolRadioButtons, rows, portInput));
  });
});
